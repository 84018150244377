import React, { useState, useEffect } from 'react'
import format from 'date-fns/format'
import get from 'lodash/get'

import MediaList from 'components/MediaList'
// import * as api from 'api'
import { getBlogPosts } from '../contentful'

export default function RecentBlog () {
  const [recentBlog, setRecentBlog] = useState([])
  const [isBlogLoading, setBlogLoading] = useState(true)

  useEffect(() => {
    async function fetchBlog() {
      try {
        const response = await getBlogPosts(1, 2)
        const formatted: [MediaItem] = response.items.map(it => ({
          id: it.sys.id,
          title: it.fields.title,
          subtitle: format(new Date(it.fields.oldDate || it.sys.createdAt), 'YYYY.MM.DD'),
          imageURL: get(it, 'asset.fields.file.url'),
          link: `/blog/${it.sys.id}`,
        }))
        setRecentBlog(formatted)
      } finally {
        setBlogLoading(false)
      }
    }
    fetchBlog()
  }, [])

  return <MediaList
    title="Recent Blog"
    items={recentBlog}
    loading={isBlogLoading}
  />
}
import { useEffect } from 'react'

export default function useWindowScroll (handler, deps) {
  const process = () => {
    handler()
    window.addEventListener('scroll', handler)
    return () => {
      window.removeEventListener('scroll', handler)
    }
  }
  if (deps) {
    useEffect(process, deps)
  } else {
    useEffect(process)
  }
}

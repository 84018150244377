import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import GlobalNav from 'components/GlobalNav'
import logoMark from 'assets/images/logo-mark.svg'

const Wrapper = styled.header.attrs({
  className: 'd-flex justify-content-between'
})`
  position: fixed;
  height: 70px;
  width: 100%;
  z-index: 900;
  background-color: rgba(255,255,255,.75);

  @media (max-width: 575px) {
    padding-left: 12.53333333%;
    padding-right: 12.53333333%;
  }
`

const Button = styled.button`
  outline: none;
  margin-left: 16px;
  border: 0;
  background-color: transparent;
  position: relative;
  z-index: 1001;
  cursor: pointer;

  :focus {
    outline: none;
  }

  @media (max-width: 575px) {
    margin: 0;
    padding: 0;
  }
`

const NavContainer = styled.div`
  background-color: rgba(255,255,255,.9);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  padding-top: 70px;
  padding-bottom: 70px;
  overflow-y: auto;
`

const LogoMark = styled.img.attrs({
  src: logoMark,
})`
  width: 38px;
  height: 38px;
  margin-top: 16px;
  margin-right: 16px;

  @media (max-width: 575px) {
    margin-right: 0;
  }
`

export default function GlobalHeader () {
  const [isNavOpened, setNavOpened] = useState(false)

  function handleMenuButtonClick () {
    setNavOpened(!isNavOpened)
  }

  return (
    <Wrapper>
      {isNavOpened && (
        <NavContainer>
          <div className="container">
            <GlobalNav />
          </div>
        </NavContainer>
      )}
      <Button
        onClick={handleMenuButtonClick}
      >
        <i className="material-icons">
          {isNavOpened ? 'close' : 'menu'}
        </i>
      </Button>
      <Link to="/">
        <LogoMark />
      </Link>
    </Wrapper>
  )
}

import React, { useEffect, useState } from 'react'
import { withRouter, Route } from 'react-router-dom'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'

import GlobalHeader from 'components/GlobalHeader'
import Body from 'components/Body'
import GallerySlider from 'components/GallerySlider'
import Product from 'pages/Product'
// import { fetchProducts } from 'api'
import { getProducts } from '../contentful'

const AboveSpace = 142

function Gallery (props) {
  const [isLoading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [currentItem, setCurrentItem] = useState(items[0])
  const [paging, setPaging] = useState({})
  const isModal = get(props, 'location.state.modal', false)

  async function fetchData (page) {
    const { items: products, paging } = await getProducts(page);
    setItems([...items, ...products])
    setPaging(paging)
    setLoading(false)
    if (products.length > 0) {
      setCurrentItem(products[0])
    }
  }
  function handleItemClick (item) {
    if (item.id === currentItem.id) {
      props.history.push({
        pathname: `${props.match.path}/${item.sys.id}`,
        state: { product: item, modal: true },
      })
    }
  }
  function handleNextPageButtonClick () {
    setLoading(true)
    window.requestAnimationFrame(() => {
      fetchData(paging.current_page + 1)
    })
  }

  useEffect(() => {
    if (items.length > 0) { return }
    fetchData(1);
  }, [])

  return (
    <>
      <Helmet title="Gallery" />
      <GlobalHeader />
      <Body>
        <div className="container">
          <h1 style={{ margin: '12px 0' }}>
            {currentItem == null ? (
              <span>Gallery</span>
            ) : (
              <>
                <span>{currentItem.fields.title}</span>
                <br />
                <small>{currentItem.fields.category.fields.name}</small>
              </>
            )}
          </h1>
        </div>
        <GallerySlider
          aboveSpace={AboveSpace}
          loading={isLoading}
          items={items}
          currentItem={currentItem}
          onItemClick={handleItemClick}
          onCurrentItemChange={currentItem => setCurrentItem(currentItem)}
          isNeedNextPageButton={!isLoading && paging.current_page < paging.total_pages}
          onNextPageButtonClick={handleNextPageButtonClick}
        />
        {!isModal && <Route path="/gallery/:id" component={Product} />}
      </Body>
    </>
  )
}

Gallery.pageName = 'Gallery'

export default withRouter(Gallery)
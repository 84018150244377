import * as contentful from 'contentful'

const client = contentful.createClient({
  accessToken: 'psPF3kj1ueiH-Gdm-4MJRWOem8-r6KaVijRDohMjFAo',
  space: 'wuwsn5jym0fe',
})

const PerPage = 25

export async function getBlogPosts(page = 1, perPage = PerPage) {
  const response = await client.getEntries({
    content_type: 'blogPost',
    skip: (page - 1) * perPage,
    limit: perPage,
    order: '-sys.createdAt',
  })
  await Promise.all(response.items.map(async (item, i) => {
    if (!item.fields.picture) {
      return Promise.resolve()
    }
    item.asset = await getAsset(item.fields.picture.sys.id)
  }))
  const { total } = response
  response.paging = {
    total_pages: Math.floor(total / perPage) + ((total % perPage) > 0 ? 1 : 0),
    current_page: page,
  }
  return response
}

export async function getAsset(id) {
  return await client.getAsset(id)
}

export async function getBlogPost(id) {
  const blogPost = await client.getEntry(id)
  if (blogPost.fields.picture) {
    blogPost.asset = await getAsset(blogPost.fields.picture.sys.id)
  }
  return blogPost
}

export async function getBlogPostByOldId(oldId) {
  const response = await client.getEntries({
    content_type: 'blogPost',
    'fields.oldId': oldId,
  })
  const blogPost = response.items[0]
  if (blogPost.fields.picture) {
    blogPost.asset = await getAsset(blogPost.fields.picture.sys.id)
  }
  return blogPost
}

export async function getProducts(page = 1, perPage = PerPage) {
  const response = await client.getEntries({
    content_type: 'product',
    skip: (page - 1) * perPage,
    limit: perPage,
    order: '-sys.createdAt',
  })
  await Promise.all(response.items.map(async (item, i) => {
    if (!item.fields.pictures) {
      return Promise.resolve()
    }
    item.assets = await Promise.all(item.fields.pictures.map(async (pic) => {
      return await getAsset(pic.sys.id)
    }))
  }))
  const { total } = response
  response.paging = {
    total_pages: Math.floor(total / perPage) + ((total % perPage) > 0 ? 1 : 0),
    current_page: page,
  }
  return response
}

export async function getProduct(id) {
  const product = await client.getEntry(id)
  if (product.fields.pictures) {
    product.assets = await Promise.all(product.fields.pictures.map(async (pic) => await getAsset(pic.sys.id)))
  }
  return product
}
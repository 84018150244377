// @flow
import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import mainImage from 'assets/images/Main--white-bg.jpg'
import logo from 'assets/images/logo.svg'
import GlobalNav from 'components/GlobalNav'
import GlobalFooter from 'components/GlobalFooter'
import ScrollSign from 'components/ScrollSign'
import RecentBlog from 'components/RecentBlog'
import RecentProducts from 'components/RecentProducts'
import useWindowScroll from 'hooks/useWindowScroll'
import useWindowResize from 'hooks/useWindowResize'
import isElementInView from 'helpers/isElementInView'

const MainImage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: auto;
  max-width: 512px;
  margin: 0 auto;

  img {
    width: 100%;
  }
`

const MainLogo = styled.img`
  display: block;
  margin: 0 auto 53px;
  width: 180px;
`

function Home () {
  const mainImageRef = useRef(null)
  const globalNavRef = useRef(null)
  const [percentage, setPercentage] = useState(0)
  const [isGlobalNavAppeared, setGlobalNavAppeared] = useState(false)
  const [containerStyle, setContainerStyle] = useState({})

  function handleWindowScroll () {
    setPercentage(window.scrollY / window.innerWidth)
    if (globalNavRef.current) {
      setGlobalNavAppeared(isElementInView(globalNavRef.current))
    }
  }
  function handleWindowResize () {
    if (mainImageRef.current) {
      setContainerStyle({ paddingTop: `${mainImageRef.current.offsetHeight}px` })
    }
  }
  function handleMainImageLoad () {
    window.requestAnimationFrame(() => {
      handleWindowResize()
      handleWindowScroll()
    })
  }
  function handleScrollSignClick () {
    window.scrollTo({
      top: (mainImageRef.current || {}).offsetHeight - 16,
      left: 0,
      behavior: 'smooth',
    })
  }

  useWindowScroll(handleWindowScroll, [])
  useWindowResize(handleWindowResize, [])

  const mainImageStyle = {
    filter: `blur(${percentage > 1 ? 100 : percentage * 100}px)`,
    opacity: 1.0 - percentage,
  }

  return (
    <>
      <MainImage
        style={mainImageStyle}
        ref={mainImageRef}
      >
        <img
          src={mainImage}
          alt="main"
          onLoad={handleMainImageLoad}
        />
      </MainImage>
      <div style={containerStyle} className="container">
        <MainLogo src={logo} alt="abi metal works" />
        <div className="row">
          <div className="mb-5 col-sm-8 offset-sm-2" ref={globalNavRef}>
            <GlobalNav negativeMargin={true} />
          </div>
        </div>
      </div>
      {!isGlobalNavAppeared && <ScrollSign onClick={handleScrollSignClick} />}
      <RecentBlog />
      <RecentProducts />
      <GlobalFooter />
    </>
  )
}

Home.pageName = 'Home'

export default Home

import React from 'react'
import styled from 'styled-components'

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

function MutatingDots (props) {
  return (
    <svg id="goo-loader" width={props.width} height={props.height} fill={props.color}>
      <filter id="fancy-goo">
        <feGaussianBlur in="SourceGraphic" stdDeviation="6" result="blur" />
        <feColorMatrix
          in="blur"
          mode="matrix"
          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
          result="goo"
        />
        <feComposite in="SourceGraphic" in2="goo" operator="atop" />
      </filter>
      <g filter="url(#fancy-goo)">
        <animateTransform
          id="mainAnim"
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          from="0 50 50"
          to="359 50 50"
          dur="1.2s"
          repeatCount="indefinite"
        />
        <circle cx="50%" cy="40" r="11">
          <animate
            id="cAnim1"
            attributeType="XML"
            attributeName="cy"
            dur="0.6s"
            begin="0;cAnim1.end+0.2s"
            calcMode="spline"
            values="40;20;40"
            keyTimes="0;0.3;1"
            keySplines="0.175 0.885 0.320 1; 0.175 0.885 0.320 1"
          />
        </circle>
        <circle cx="50%" cy="60" r="11">
          <animate
            id="cAnim2"
            attributeType="XML"
            attributeName="cy"
            dur="0.6s"
            begin="0.4s;cAnim2.end+0.2s"
            calcMode="spline"
            values="60;80;60"
            keyTimes="0;0.3;1"
            keySplines="0.175 0.885 0.320 1; 0.175 0.885 0.320 1"
          />
        </circle>
      </g>
    </svg>
  )
}

export default function Loader (props) {
  const loader = <MutatingDots
    color="#dabe79"
    height="100"
    width="100"
  />

  if (props.container) {
    return (
      <LoaderContainer> 
        {loader}
      </LoaderContainer>
    )
  }
  return loader
}

// @flow
import React from 'react'
import chunk from 'lodash/chunk'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Loader from 'components/Loader'

export type MediaItem = {
  id: number,
  title: string,
  imageURL: string,
  date: Date,
}

type Props = {
  title: string,
  items: [MediaItem],
  loading: boolean,
}

const Img = styled.img.attrs({
  className: 'rounded img-fluid d-block mb-3',
})`
  width: 100%;
`

function MediaList (props: Props) {
  return (
    <div className="container mb-5">
      <div className="row">
        <div className="col-6 offset-sm-2 mb-3">
          <h6>{props.title}</h6>
        </div>
      </div>
      {props.loading && (
        <div className="text-center"><Loader /></div>
      )}
      {chunk(props.items, 2).map((items, i) => (
        <div className="row" key={i}>
          {items.map((item, _i) => (
            <div
              className={classnames('col-6 col-sm-4', { 'offset-sm-2': _i === 0 })}
              key={_i}
            >
              <figure>
                <Link
                  to={item.link}
                  className="silent"
                >
                  <Img
                    src={item.imageURL}
                    alt={item.title}
                  />
                  <figcaption>
                    {item.title}
                    <br />
                    <span>{item.subtitle}</span>
                  </figcaption>
                </Link>
              </figure>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default MediaList

import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import GlobalHeader from 'components/GlobalHeader'
import Body from 'components/Body'
import GlobalFooter from 'components/GlobalFooter'

export default function NotFound () {
  return (
    <>
      <Helmet>
        <title>404 Not Found</title>
      </Helmet>
      <GlobalHeader />
      <Body>
        <div className="container">
          <h1 className="mt-5 mb-3">404 Not Found</h1>
          <p>Please back to <Link to="/">home</Link>.</p>
        </div>
      </Body>
      <GlobalFooter />
    </>
  )
}
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'

// import { fetchProduct } from 'api'
import { getProduct } from '../contentful'
import Loader from 'components/Loader'
import ProductViewer from 'components/ProductViewer'


function Product (props) {
  const [isLoading, setLoading] = useState(true)
  const [product, setProduct] = useState(get(props, 'location.state.product', null))

  useEffect(() => {
    if (product) { return setLoading(false) }
    async function fetchData() {
      const product = await getProduct(props.match.params.id)
      setProduct(product)
      setLoading(false)
    }
    fetchData()
  }, [])

  return (
    <div>
      <Helmet title={product ? product.title : 'Gallery'} />
      {isLoading || product == null ? (
        <Loader container />
      ) : (
        <ProductViewer
          product={product}
        />
      )}
    </div>
  )
}

Product.pageName = 'Product'

export default withRouter(Product)
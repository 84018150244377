import React, { useState, useEffect, useRef } from 'react'

import Loader from '../components/Loader'
import {
  Wrapper,
  Inner,
  Item,
  ItemImage,
  Gradient,
  // Button,
  NextPageButton,
} from './GallerySlider/styled'


export default function GallerySlider (props) {
  const [wrapperHeight, setWrapperHeight] = useState(0)
  const [itemWidth, setItemWidth] = useState(0)
  const [isScrollEnd, setScrollEnd] = useState(false)
  const innerRef = useRef(null)

  useEffect(() => {
    checkInnerScroll()
    function resizeHandler () {
      setWrapperHeight(window.innerHeight - props.aboveSpace)
      setItemWidth(window.innerWidth * 0.7493333334)
    }
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  })

  function checkInnerScroll () {
    if (innerRef.current === null || itemWidth === 0) { return }
    const borderLine = innerRef.current.scrollWidth - 50
    setScrollEnd(innerRef.current.scrollLeft + innerRef.current.offsetWidth > borderLine)
    let index = Math.round(innerRef.current.scrollLeft / (itemWidth + 24))
    if (index > props.items.length - 1) { index = props.items.length - 1 }
    const currentItem = props.items[index]
    props.onCurrentItemChange(currentItem)
  }
  function handleInnerScroll () {
    checkInnerScroll()
  }

  const wrapperStyle = { height: `${wrapperHeight}px` }
  const itemStyle = { width: `${itemWidth}px` }

  return (
    <Wrapper style={wrapperStyle}>
      <Inner
        loading={props.loading}
        ref={innerRef}
        onScroll={handleInnerScroll}
      >
        {props.loading && <Loader container />}
        {props.items.map((item, i) => (
          <Item
            key={i}
            active={item.sys.id === ((props.currentItem || {}).sys || {}).id}
            onClick={e => {
              e.preventDefault()
              props.onItemClick(item)
            }}
            style={itemStyle}
          >
            {item.assets && item.assets.length > 0 && (
              <ItemImage src={item.assets[0].fields.file.url} />
            )}
            <Gradient />
          </Item>
        ))}
      </Inner>
      {props.isNeedNextPageButton && (
        <NextPageButton
          active={isScrollEnd}
          onClick={() => {
              setScrollEnd(false)
            window.requestAnimationFrame(() => {
            props.onNextPageButtonClick()
            })
          }}
        >
          <i className="material-icons">keyboard_arrow_right</i>
        </NextPageButton>
      )}
    </Wrapper>
  )
}
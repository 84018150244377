import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import GlobalHeader from '../components/GlobalHeader'
import GlobalFooter from '../components/GlobalFooter'
import Body from '../components/Body'
import profileImage from '../assets/images/profile-2015.jpg'
import tomihiro from '../assets/images/tomihiro.jpg'
import atom from '../assets/images/atom.jpg'
import tokine from '../assets/images/tokine.jpg'

const Name = styled.h1.attrs({ className: 'mb-4' })`
  font-size: 16px;
`

function Profile () {
  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <GlobalHeader />
      <Body>
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <h1 className="my-5">Profile</h1>
              <article className="article mb-4">
                <img
                  src={profileImage}
                  alt="山形 亜瞳"
                  className="article-image img-fluid mb-5"
                />
                <div className="article-body">
                  <Name>山形 亜瞳&nbsp;&nbsp;Abi Yamagata</Name>
                  <p>1984年 広島県出身<br />in 1984, Hiroshima Japan</p>

                  <p>丸い形をモチーフに、そこから広がる世界を作品のコンセプトにしています。<br />
                  丸から連想できることは無限大で、何にでも繋がってゆきます。<br />
                  永遠に続く時間軸の中からときめくものを探して行きたいです。</p>
                  <p>My works' concept is <i>the circle shape</i> and spreading the world from it.<br />
                  The imagination from the circle shape is infinite and able to connect to everything.<br />
                  I'm going to keep to find special things in an infinite timeline.</p>
                </div>
              </article>

              <h2 className="my-5">取扱店舗</h2>

              <img src={tomihiro} className="img-fluid mb-4" alt="銀座とみひろ" />
              <h6>銀座とみひろ</h6>
              <p>東京都中央区銀座5-8-20 銀座コア 5F<br />
                <a href="https://goo.gl/maps/zdMi8kBFTpmJzhwN9" target="_blank" rel="noopener noreferrer">Google map</a>
                &nbsp;/&nbsp;
                <a href="http://www.tomihiro.co.jp" target="_blank" rel="noopener noreferrer">Web site</a>
              </p>

              <img src={atom} className="img-fluid mt-5 mb-4" alt="アトムリビンテック GALLERY SHOP KANAGU" />
              <h6>アトムリビンテック GALLERY SHOP KANAGU</h6>
              <p>東京都港区新橋4-31-5 アトムCSタワー 1F<br />
                <a href="https://goo.gl/maps/RwaGwEYzmHD2" target="_blank" rel="noopener noreferrer">Google map</a>
                &nbsp;/&nbsp;
                <a href="http://www.atomlt.com/cstower/" target="_blank" rel="noopener noreferrer">Web site</a>
              </p>

              <img src={tokine} className="img-fluid mt-5 mb-4" alt="ギャラリートキネ" />
              <h6>ギャラリートキネ</h6>
              <p>広島県広島市中区舟入川口町5-12<br />
                <a href="https://goo.gl/maps/9Cdtk1qcsw1azkC39" target="_blank" rel="noopener noreferrer">Google map</a>
                &nbsp;/&nbsp;
                <a href="https://www.tokine-gallery.com/" target="_blank" rel="noopener noreferrer">Web site</a>
              </p>
            </div>
          </div>
        </div>
      </Body>
      <GlobalFooter />
    </>
  )
}

Profile.pageName = 'Profile'

export default Profile

import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
`

export const Inner = styled.div`
  position: relative;
  padding-top: 16px;
  padding-left: 12.53333333%;
  padding-right: 12.53333333%;
  padding-bottom: 16px;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  height: 100%;

  ::after {
    content: '';
    min-width: 12.53333333%;
    height: 100%;
    flex: 0 0 auto;
  }

  ${props => props.loading && `
    justify-content: center;

    ::after {
      display: none;
    }
  `}
`

export const Item = styled.div`
  position: relative;
  flex: 0 0 auto;
  min-width: 240px;
  height: 100%;
  max-height: 400px;
  background-color: #000;
  margin-right: 24px;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba(0,0,0,.2);
  overflow: hidden;

  :last-child {
    margin-right: 0;
  }

  ${props => props.active && `
    cursor: pointer;

    img,
    button {
      opacity: 1;
    }
  `}
`

export const ItemImage = styled.img`
  transition-property: opacity, transform;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.25;
  z-index: 5;
`

export const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.54) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.54) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.54) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#8a000000',GradientType=0 ); /* IE6-9 */
`

export const Button = styled.button.attrs({
  className: 'btn btn-outline-light btn-sm'
})`
  transition-property: opacity;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  position: absolute;
  right: 16px;
  bottom: 16px;
  border-radius: 19px;
  opacity: 0;
  font-size: 12px;
  font-weight: bold;
  padding: 8px 16px;
`

export const NextPageButton = styled.button`
  transition-property: right;
  transition-duration: 0.25s;
  display: flex;
  position: absolute;
  top: 50%;
  right: -100%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: #28a745;
  border-radius: 50%;
  color: #fff;
  border: 0;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0,0,0,.1);

  ${props => props.active && `
    right: 8px;
  `}

  .material-icons {
    font-size: 28px;
  }
`

import React, { useState, useEffect } from 'react'

import MediaList from 'components/MediaList'
// import * as api from 'api'
import { getProducts } from '../contentful'

export default function RecentProducts () {
  const [recentProducts, setRecentProducts] = useState([])
  const [isProductsLoading, setProductsLoading] = useState(true)

  useEffect(() => {
    async function fetchProducts() {
      try {
        const { items: products } = await getProducts(1, 2)
        // const { products } = await api.fetchProducts(1, 2)
        const formatted: [MediaItem] = products.map(it => ({
          id: it.sys.id,
          subtitle: `${it.fields.category.fields.name}`,
          title: it.fields.title,
          imageURL: it.assets[0].fields.file.url,
          link: `/gallery/${it.sys.id}`,
        }))
        setRecentProducts(formatted)
      } finally {
        setProductsLoading(false)
      }
    }
    fetchProducts()
  }, [])

  return <MediaList
    title="Recent Works"
    items={recentProducts}
    loading={isProductsLoading}
  />
}

import React from 'react'

export default function GlobalFooter () {
  return (
    <footer className="global-footer text-center pb-3">
      <div>
        <small>Copyright {new Date().getFullYear()} abi metal works</small>
      </div>
    </footer>
  )
}
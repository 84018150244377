import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.main`
  padding-top: 70px;
`

export default function Body (props) {
  return (
    <Wrapper>{props.children}</Wrapper>
  )
}
import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'


const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

function ProductViewer (props) {
  const [picIndex, setPicIndex] = React.useState(0)
  const imageURLs = props.product.assets.map(pic => pic.fields.file.url)

  return (
    <Wrapper>
      <Lightbox
        mainSrc={imageURLs[picIndex]}
        nextSrc={imageURLs[(picIndex + 1) % imageURLs.length]}
        prevSrc={imageURLs[(picIndex + imageURLs.length - 1) % imageURLs.length]}
        clickOutsideToClose={false}
        onCloseRequest={() => props.history.push('/gallery')}
        imageTitle={props.product.fields.title}
        imageCaption={`${props.product.fields.category.fields.name}`}
        onMoveNextRequest={() => {
          setPicIndex((picIndex + 1) % imageURLs.length)
        }}
        onMovePrevRequest={() => {
          setPicIndex((picIndex + imageURLs.length - 1) % imageURLs.length)
        }}
      />
    </Wrapper>
  )
}

export default withRouter(ProductViewer)
import React, { useState, useEffect } from 'react'
import truncate from 'lodash/truncate'
import styled from 'styled-components'
import format from 'date-fns/format'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import GlobalHeader from 'components/GlobalHeader'
import GlobalFooter from 'components/GlobalFooter'
import Body from 'components/Body'
import Loader from 'components/Loader'
import { getBlogPosts } from '../contentful'

const Excerpt = styled.p.attrs({
  className: 'mb-2'
})`
  font-size: 12px;
  color: #999;
`
const Info = styled.div`
  font-size: 12px;
`
const TitleLink = styled(Link)`
  color: #666;
`

const Thumb = styled.img.attrs({ className: 'ml-4' })`
  width: 100px;

  @media (max-width: 575px) {
    width: 80px;
  }
`

const makeRoute = item => ({
  pathname: `/blog/${item.sys.id}`,
  state: { article: item },
})

function Blog () {
  const [isLoading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [paging, setPaging] = useState({})

  async function fetchData (page) {
    const response = await getBlogPosts(page)
    setItems([...items, ...response.items])
    setPaging(response.paging)
    setLoading(false)
  }
  function handleLoadMoreButtonClick () {
    setLoading(true)
    fetchData(paging.current_page + 1)
  }

  useEffect(() => {
    if (items.length > 0) { return }
    fetchData(1)
  }, [])

  return (
    <>
      <Helmet title="Blog" />
      <GlobalHeader />
      <Body>
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <h1 className="my-5">
                <span>Blog</span>
              </h1>
              {isLoading && <Loader container />}
              {items.map((item, i) => (
                <article key={i} className="mb-5 media">
                  <div className="media-body">
                    <h6 className="font-weight-bold mb-4">
                      <TitleLink to={makeRoute(item)}>{item.fields.title}</TitleLink>
                    </h6>
                    <Excerpt>
                      {truncate(item.fields.body, { length: 50, separator: '…'})}
                    </Excerpt>
                    <Info>
                      {format(new Date(item.fields.oldDate || item.sys.createdAt), 'YYYY.MM.DD')}
                      &nbsp;・&nbsp;
                      {item.fields.category && <span>{item.fields.category.fields.name}</span>}
                    </Info>
                  </div>
                  {item.asset && (
                    <Link to={makeRoute(item)}>
                      <Thumb
                        src={item.asset.fields.file.url}
                        className="img-fluid ml-4 rounded"
                        alt={item.asset.fields.file.title}
                      />
                    </Link>
                  )}
                </article>
              ))}
              {paging.current_page < paging.total_pages && (
                <div className="text-center mb-5">
                  <button
                    className="btn btn-link"
                    onClick={handleLoadMoreButtonClick}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Loading...' : 'Load More'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Body>
      <GlobalFooter />
    </>
  )
}

Blog.pageName = 'Blog'

export default Blog

import React from 'react'
import ReactGA from 'react-ga'
import { withRouter } from 'react-router-dom'

const isProduction = process.env.NODE_ENV === 'production'

if (isProduction) {
  ReactGA.initialize('UA-34880327-4')
}

class GATracker extends React.Component {
  componentDidMount () {
    const page = this.props.location.pathname + this.props.location.search
    this.trackPage(page)
  }

  componentDidUpdate (prevProps) {
    const currentPage = prevProps.location.pathname + prevProps.location.search
    const nextPage = this.props.location.pathname + this.props.location.search

    if (currentPage !== nextPage) {
      this.trackPage(nextPage)
    }
  }

  render () {
    return this.props.children
  }

  trackPage (page) {
    if (!isProduction) { return }
    const options = this.props.options || {}
    ReactGA.set({ page, ...options })
    ReactGA.pageview(page)
  }
}

export default withRouter(GATracker)
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: -5px;
  text-align: center;
  color: rgba(0,0,0,.5);
  text-shadow: 0 0 5px rgba(0,0,0,.2);

  .material-icons {
    font-size: 32px;
    border-radius: 50%;
    background-color: rgba(255,255,255,.75);
  }
`

export default function ScrollSign (props) {
  return (
    <Wrapper onClick={e => typeof props.onClick === 'function' && props.onClick(e)}>
      <i className="material-icons">keyboard_arrow_down</i>
    </Wrapper>
  )
}
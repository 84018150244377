import * as React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import 'assets/stylesheets/container.scss'
import 'assets/stylesheets/global.scss'

import Home from './pages/Home'
import Profile from './pages/Profile'
import Gallery from './pages/Gallery'
import Product from './pages/Product'
import Blog from './pages/Blog'
import Article from './pages/Article'
import NotFound from './pages/NotFound'

import ScrollToTop from './components/ScrollToTop'
import GATracker from './components/GATracker'

type Props = {}

const App: React.FC<Props> = () => {
  const location = useLocation<{ modal: undefined | boolean }>()
  const isModal = (location.state || {}).modal || false

  return (
    <>
      <Helmet
        defaultTitle="abi metal works"
        titleTemplate="%s - abi metal works"
      />
      <ScrollToTop>
        <GATracker>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/profile" component={Profile} />
            <Route path="/blog/:id" component={Article} />
            <Route path="/blog" component={Blog} />
            <Route path="/gallery" component={Gallery} />
            <Route component={NotFound} />
          </Switch>
          {isModal && <Route path={`/gallery/:id`} component={Product} />}
        </GATracker>
      </ScrollToTop>
    </>
  )
}

export default App

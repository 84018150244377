import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import format from 'date-fns/format'
import get from 'lodash/get'
import { Link } from 'react-router-dom'
import Markdown from 'react-markdown'

import GlobalHeader from 'components/GlobalHeader'
import GlobalFooter from 'components/GlobalFooter'
import Body from 'components/Body'
import Loader from 'components/Loader'
import RecentBlog from 'components/RecentBlog'
import RecentProducts from 'components/RecentProducts'
import { getBlogPost, getBlogPostByOldId } from '../contentful'

const BlogContent = styled.div`
  word-break: break-all;
  img {
    max-width: 100% !important;
    height: auto;
  }
`

export default function Article (props) {
  const [item, setItem] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if (!!item) { return }
    async function fetchData() {
      const id = props.match.params.id
      let article
      if (/^[0-9]+$/.test(id)) {
        article = await getBlogPostByOldId(id)
      } else {
        article = await getBlogPost(id)
      }
      setItem(article)
      setLoading(false)
    }
    fetchData()
  }, [])

  return (
    <>
      <Helmet title={item ? item.fields.title : 'Blog'} />
      <GlobalHeader />
      <Body>
        <div className="container">
          <div className="row">
            <div className="col-sm-8 offset-sm-2">
              <h1 className="my-5">
                {!isLoading && item ? (
                  <>
                    <span>{item.fields.title}</span>
                    <br />
                    <small>{format(new Date(item.fields.oldDate || item.sys.createdAt), 'YYYY.MM.DD')}・{get(item, 'fields.category.fields.name')}</small>
                  </>
                ) : (
                  <span>Blog</span>
                )}
              </h1>
              {isLoading ? (
                <Loader container />
              ) : (
                <>
                  {item.asset && (
                    <img
                      className="img-fluid mb-4"
                      src={item.asset.fields.file.url}
                      alt={item.fields.title}
                    />
                  )}
                  <BlogContent>
                    <Markdown source={item.fields.body} />
                  </BlogContent>
                </>
              )}
            </div>
          </div>
        </div>
      </Body>
      {!isLoading && (
        <>
          <div className="py-5 text-center">
            <Link to="/blog" className="btn btn-link">Back to index</Link>
          </div>
          <RecentBlog />
          <RecentProducts />
          <GlobalFooter />
        </>
      )}
    </>
  )
}
import React from 'react'
import styled from 'styled-components'

import { withRouter } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

import instagramLogo from '../assets/images/instagram.svg'

const Wrapper = styled.nav``

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  ${props => props.negativeMargin && `
    margin-left: -15px;
    margin-right: -15px;
  `}
`

const ListItem = styled.li`
  font-size: 18px;
  border-bottom: 1px solid #E8E8E8;

  :last-child {
    border-bottom: 0;
  }
`

const Link = styled(NavLink)`
  padding: 15px;
  color: #393939;
  display: block;

  &.active {
    border-left: 3px solid #a8cec0;
  }

  &:hover {
    text-decoration: none;
    background-color: rgba(0,0,0,.05);
  }
`

function GlobalNav (props) {
  return (
    <Wrapper>
      <List negativeMargin={props.negativeMargin}>
        {props.match.path !== '/' && (
          <ListItem>
            <Link exact to="/">Home</Link>
          </ListItem>
        )}
        <ListItem>
          <Link to="/profile">Profile</Link>
        </ListItem>
        <ListItem>
          <Link to="/gallery">Gallery</Link>
        </ListItem>
        <ListItem>
          <Link to="/blog">Blog</Link>
        </ListItem>
        <ListItem>
          <Link
            as="a"
            href="https://www.instagram.com/abi.metalworks/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={instagramLogo}
              width="24"
              height="24"
              alt="instagram"
              className="mr-2"
            />
            instagram
          </Link>
        </ListItem>
      </List>
    </Wrapper>
  )
}

export default withRouter(GlobalNav)
